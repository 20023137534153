<template>
    <OrderForm :edit="true" />
</template>

<script>
import OrderForm from '@/components/Order/OrderForm'

export default {
    name: "EditOrder",
    components: {
        OrderForm
    },
    computed: {
        order() {
          return this.$store.state.orders.currentOrder
        },
        breadcrumbs() {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Заказ ПО', link: '/' },
                { title: 'Перечень заказов на поставку', link: '/purchase-orders' },
                { title: `Заказ на поставку № ${this.order.number} от ${this.$options.filters.parseDate(this.order.order_date)}`, link: `/purchase-orders/${this.$route.params.id}` },
                { title: 'Редактирование' }
            ]
        }
    }
}
</script>

<style scoped>

</style>
